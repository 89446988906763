.mainHeader {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: var(--color-primario1);
  color: var(--color-white);
}

.mainHeader__title {
  font-weight: bold;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.mainHeader__sendRequest {
  flex-shrink: 0;
}

@media (--not-tablet) {
  .mainHeader__sendRequest.mainHeader__sendRequest {
    min-height: 0;
  }

  .mainHeader__sendRequest.mainHeader__sendRequest > * {
    font-size: var(--font-size-10px);
  }

  .mainHeader__title {
    font-size: var(--font-size-12px);
  }
}

@media (--tablet) {
  .mainHeader {
    justify-content: center;
  }

  .mainHeader__sendRequest {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
}

.nav {
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main > * {
  flex-grow: 1;
}

.toast__body {
  padding: 1rem;
}
