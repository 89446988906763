.subtotal {
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: var(--font-weight-bold);
}

.subtotal__label {
  color: var(--a11y-text-color-over-white);
  text-transform: capitalize;
}

.subtotal__value {
  color: var(--color-black);
}
