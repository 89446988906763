.Foam {
  position: relative;
  overflow: hidden;
  list-style: none;
}

.DraggableFoam:hover,
.DraggableFoam:focus {
  cursor: move;
  cursor: grab;
}

.FoamPreview {
  position: relative;
  margin: 0;
  padding: 0;
  background: black;
}

.FoamPreview__img {
  display: block;
}

.FoamPreview__sku {
  position: absolute;
  right: 0;
  top: 0;
  padding: .1em .2em;
  transform: all .5s ease;
  background-color: var(--color-primario1);
}

.AddToCartMini {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.AddToCartMini__icon {
  height: 2rem;
  width: 2rem;
  background-color: var(--color-white);
}

/**
 * 1. Always display a '-' inside button, not '+'
 */
.AddToCartMini__icon::before {
  display: none; /* [1] */
}

.AddToCartMini__icon::after {
  background-color: var(--color-black);
  height: 3px;
}
