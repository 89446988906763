.step {
  display: flex;
  flex-grow: 1;
  font-size: var(--base-font-size);
  align-self: stretch;
}

@media (--tablet) {
  .step {
    flex-basis: 20%;
  }
}

.step__link {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: .4em;
  text-decoration: none;
  background-color: var(--step-bgcolor);
  color: var(--step-color);
  border: 3px solid transparent;
  border-bottom-color: var(--step-border-color);
}

.step__link:hover,
.step__link:focus {
  cursor: pointer;
}

.step__link[disabled] {
  cursor: not-allowed;
}

.step__link--active {
  background-color: var(--active-step-bgcolor);
  border-bottom-color: var(--active-step-border-color);
  color: var(--active-step-color);
}

.step__summary {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  margin-bottom: .5em;
  background-color: var(--step-summary-bgcolor);
}

.step__summary:empty {
  background-color: var(--empty-step-summary-bgcolor);
}

.step--active .step__summary {
  background-color: var(--active-step-summary-bgcolor);
  color: var(--active-step-summary-color);
}

.step__colorIcon {
  display: inline-block;
  width: var(--font-size-13px);
  height: var(--font-size-13px);
  border-radius: 50%;
  border: 1px solid var(--color-primario3);
}

.step__name {
  font-size: 1em;
}

@media (--small-devices) {
  .step__name {
    font-size: var(--font-size-11px);
  }
}
