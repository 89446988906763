.fieldset {
  width: 100%;
  border: none;
  padding: 1em var(--horizontal-padding-main);
}

@media (--desktop) {
  .fieldset {
    padding-left: 0;
    padding-right: 0;
  }
}

.colorSelector {
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
}

.color {
  flex-shrink: 0;
}

.color__control {
  position: absolute;
  opacity: 0;
}

.color__label {
  display: inline-block;
  font-size: .8em;
  text-align: center;
}

.color__label:hover,
.color__label:focus {
  cursor: pointer;
}

.color__ico {
  display: block;
  width: 4em;
  margin: 0 auto .5em;
  border: 1px solid transparent;
  border-radius: 50%;
  /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
  background-color: currentColor;
  background-clip: content-box;
  padding: .2em;
  content: "";
}

.color__ico::before {
  display: block;
  padding-bottom: 100%;
  content: "";
}

.color__control:checked + .color__label > .color__ico {
  border-color: black;
}
