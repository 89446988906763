/**
 * Use a comfortable measure in all browsers (opinionated).
 */

html {
  padding: 1em;
}

@media (min-width: 34em) {
  html {
    padding: 1em calc(50% - 16em);
  }
}

/**
 * Restrict sizing to the page width in all browsers (opinionated).
 */

iframe,
img,
input,
select,
textarea {
  height: auto;
  max-width: 100%;
}
