.wrapper {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: space-around;
  background-color: var(--btn-next-wrapper-bgcolor);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .5);
  z-index: var(--z-index-over);
}

@keyframes bounce-right {
  0% { transform: translateX(0); }
  50% { transform: translateX(30%); }
  100% { transform: translateX(0); }
}

.navButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border: none;
  border-radius: 1em;
  background-color: var(--btn-next-bgcolor);
  color: var(--btn-next-color);
  font-size: var(--btn-next-font-size);
  font-weight: var(--btn-next-font-weight);
  text-transform: uppercase;
}

.navButton__text {
  margin-right: 1em;
}

.navButton:hover,
.navButton:focus {
  color: var(--btn-next-hover-color);
  cursor: pointer;
}

.navButton:hover svg,
.navButton:focus svg {
  animation-name: bounce-right;
  animation-duration: .75s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}
