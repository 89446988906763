.PrimaryButton.PrimaryButton {
  background-color: var(--color-secundario1);
  border: 1px solid var(--color-transparent);
  color: var(--color-white);
}

.PrimaryButton.PrimaryButton:active,
.PrimaryButton.PrimaryButton:focus,
.PrimaryButton.PrimaryButton:hover {
  background-color: var(--color-secundario2);
}

.PrimaryGhostButton.PrimaryGhostButton {
  color: var(--color-black);
  background-color: var(--color-secundario1);
}

.PrimaryGhostButton.PrimaryGhostButton:active,
.PrimaryGhostButton.PrimaryGhostButton:focus,
.PrimaryGhostButton.PrimaryGhostButton:hover {
  color: var(--color-black);
  border-color: var(--color-transparent);
  background-color: var(--color-secundario2);
}
