
.u-scrollbars {
  /* Internet Explorer */
  scrollbar-face-color: var(--scrollbar-color-thumb);
  scrollbar-track-color: var(--scrollbar-color-track);

  /* W3C Working Draft */
  scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
}

.u-scrollbars::-webkit-scrollbar-thumb {
  border-radius: var(--scrollbar-border-radius-thumb);
  background-color: var(--scrollbar-color-thumb);
}

.u-scrollbars::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color-track);
}

.u-scrollbars--x {
  overflow-x: auto;
}

.u-scrollbars--x::-webkit-scrollbar {
  height: var(--scrollbar-width);
}

.u-scrollbars--y {
  overflow-y: auto;
}

.u-scrollbars--y::-webkit-scrollbar {
  width: var(--scrollbar-width);
}
