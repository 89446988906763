.Drawer {
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;

  /* flex-direction: column; */
  padding: 0;
  justify-content: flex-start;
  max-width: 100%;
  margin: 0 auto;
  border-image-slice: 100 143 89 fill;
  border-style: inset;
  border-width: 28.73px 40.2px 24.31px;
  box-sizing: content-box;
}

@media (--desktop) {
  .Drawer {
    border-width: 45.22px 63.26px 38.26px;
  }
}

/**
 * 1. Foam 3/3 proportion: 1230/1500
 */
.Drawer::before {
  display: block;
  content: '';
  float: left;
  width: 1px;
  padding-bottom: var(--images-productImage-ratio-percent); /* [1] */
}

.Drawer::after {
  display: flex;
  align-items: center;
  justify-content: center;
  content: '+';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-traslucent-primario1);
  color: var(--color-white);
  font-size: 2em;
  font-weight: var(--font-weight-bold);
  transform: scale(0);
}

.Drawer.isFoamDropping::after {
  transform: scale(1);
}
