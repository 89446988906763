:root {
  --color-primario1: #012169;
  --color-primario2: #292827;
  --color-primario3: #9b9b9b;
  --color-primario4: #f3f3f3;
  --color-secundario1: #97d700;
  --color-secundario2: #78ac00;
  --color-secundario3: #06c6fa;
  --color-secundario4: #515151;
  --color-secundario5: #00437c;
  --color-sna-europe: #00559f;
  --color-black: #000;
  --color-white: #fff;
  --color-green: #5b872c;
  --color-transparent: transparent;
  --bgcolor-base: var(--color-primario4);
  --bgcolor-product-in-cart: #f3f3f3;
  --bgcolor-stepper: #a7a8a9;
  --bgcolor-overlay: rgba(0, 0, 0, .2);
  --color-traslucent-primario1: #ed660766;

  /* BORDER */
  --border-base: solid 1px var(--color-primario4);

  /* text */
  --font-size-10px: .625rem;
  --font-size-11px: .6875rem;
  --font-size-12px: .75rem;
  --font-size-13px: .8125rem;
  --font-size-14px: .875rem;
  --font-size-16px: 1rem;
  --font-size-20px: 1.25rem;
  --font-size-30px: 1.875rem;
  --font-weight-thin: 100;
  --font-weight-heavier: 500;
  --font-weight-bold: 700;
  --base-font-size: var(--font-size-13px);

  /* A11Y */
  --a11y-target-min-height: 48px;
  --a11y-target-min-width: 48px;
  --a11y-text-color-over-bgcolor-base: var(--color-secundario4); /* AA: fails - AAA: fails */
  --a11y-text-color-over-white: var(--color-secundario4); /* AA: pass - AAA: pass */

  /* TABS */
  --tab-height: 3rem;
  --tab-bgcolor: var(--bgcolor-base);
  --tab-color: var(--a11y-text-color-over-white);
  --selected-tab-bgcolor: var(--color-white);
  --selected-tab-color: var(--color-black);
  --tabpanel-bgcolor-primary: var(--selected-tab-bgcolor);
  --tabpanel-bgcolor-secondary: var(--color-white);
  --tabpanel-color-primary: var(--selected-tab-color);
  --tabpanel-color-secondary: var(--tabpanel-color-primary);

  /* MOBILE TABLES */
  --mobile-table-header-bgcolor: #a7a8a9;

  /* STEPS */
  --step-bgcolor: var(--bgcolor-stepper); /* var(--color-primario2); */
  --step-border-color: transparent;
  --step-color: var(--color-black);
  --step-summary-bgcolor: transparent;
  --step-summary-color: currentColor;
  --active-step-bgcolor: var(--step-bgcolor);
  --active-step-border-color: var(--color-secundario1);
  --active-step-color: var(--color-white);
  --active-step-summary-bgcolor: var(--step-summary-bgcolor);
  --active-step-summary-color: var(--color-white);
  --empty-step-summary-bgcolor: transparent;

  /* FORMS */
  --custom-radio-bgcolor: transparent;
  --custom-radio-border: 1px solid var(--color-black);
  --selected-custom-radio-bgcolor: var(--color-primario3);
  --selected-custom-radio-border: var(--custom-radio-border);
  --legend-font-size: var(--font-size-11px);
  --legend-font-weight: var(--font-weight-thin);
  --legend-margin: 0 0 .5rem;
  --legend-text-transform: uppercase;

  /* BUTTONS */
  --btn-font-size: var(--font-size-13px);
  --btn-font-weight: var(--font-weight-bold);
  --btn-min-height: var(--a11y-target-min-height);
  --btn-min-width: 10em;

  /* NEXT BUTTONS */
  --btn-next-wrapper-bgcolor: var(--bgcolor-base);
  --btn-next-bgcolor: var(var(--bgcolor-base));
  --btn-next-color: var(--color-primario1);
  --btn-next-font-size: var(--btn-font-size);
  --btn-next-font-weight: var(--btn-font-weight);
  --btn-next-hover-color: var(--color-secundario1);

  /* SAVE CONFIG BUTTON */
  --btn-saveConfig-wrapper-bgcolor: var(--color-white);
  --btn-saveConfig-bgcolor: var(--color-secundario1);
  --btn-saveConfig-color: var(--color-white);
  --btn-saveConfig-font-size: var(--btn-font-size);
  --btn-saveConfig-font-weight: var(--btn-font-weight);

  /* GUTTERS */
  --horizontal-margin-base--mobile: 1rem; /* 16px */
  --horizontal-margin-base--desktop: 1.5rem; /* 24px */
  --horizontal-margin-base: var(--horizontal-margin-base--mobile);
  --horizontal-margin-main: var(--horizontal-margin-base);
  --horizontal-margin-mobile-fullwidth-component: calc(-1 * var(--horizontal-margin-main));
  --horizontal-padding-base--mobile: 1.5rem; /* 24px */
  --horizontal-padding-base--desktop: 1.5rem; /* 24px */
  --horizontal-padding-base: var(--horizontal-padding-base--mobile);
  --horizontal-padding-main: var(--horizontal-padding-base);

  /* ACCORDIONS */
  --accordion-item-panel-padding: 2em;

  /* Scrollbars customization */
  --scrollbar-border-radius-thumb: 4px;
  --scrollbar-color-thumb: var(--color-primario3);
  --scrollbar-color-track: transparent;
  --scrollbar-width: 8px;

  /* Z-INDEX */
  --z-index-over: 10;

  /* SKU related */
  --sku-color: #a7a8a9;
  --sku-font-weight: var(--font-weight-bold);

  /* Icons */
  --icon-height: 1.5rem;
  --icon-width: 1.5rem;

  /* Images */
  --images-productListItem-width: 88px;
  --images-productListItem-width--desktop: 160px;
  --images-productImage-ratio-percent: 82%; /* 1230/1500 */
}

@custom-media --small-devices (max-width: 24.999em); /* < 400px */
@custom-media --not-tablet (max-width: 47.999em); /* < 768px */
@custom-media --tablet (min-width: 48em); /* >= 768px */
@custom-media --not-desktop (max-width: 63.999em); /* < 1024px */
@custom-media --desktop (min-width: 64em); /* >= 1024px */

@media (--tablet) { /* >= 768px */
  :root {
    --base-font-size: var(--font-size-16px);
    --legend-font-size: var(--font-size-20px);
    --legend-margin: 0 0 1.5rem;
  }
}

@media (--desktop) {
  :root {
    --horizontal-margin-base: var(--horizontal-margin-base--desktop);
    --horizontal-margin-main: var(--horizontal-margin-base);
    --horizontal-margin-mobile-fullwidth-component: 0;
    --horizontal-padding-base: var(--horizontal-padding-base--desktop);
    --horizontal-padding-main: var(--horizontal-padding-base);
  }
}
