.drawerSelector {
  overflow-x: auto;
}

.radioFieldset__legend {
  text-transform: none;
  padding-left: var(--horizontal-padding-base);
}

@media (--desktop) {
  .radioFieldset__legend {
    clip: rect(0 0 0 0);
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    white-space: nowrap;
    clip-path: inset(100%);
  }
}

.radioFieldset__container {
  display: flex;
  font-size: .9em;
}

.radio {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 5rem;
}

.radio + * {
  margin-left: 1px;
}

.radio__input {
  position: absolute;
  opacity: 0;
}

.radio__label {
  display: flex;
  flex: 1 1;
}

.DrawerSelectorItem {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  min-width: var(--a11y-target-min-width);
  height: var(--tab-height);
  min-height: var(--a11y-target-min-height);
  background-color: var(--tab-bgcolor);
  color: var(--tab-color);
  cursor: pointer;
}

.DrawerSelectorItem.isFoamDropping {
  background-color: var(--color-primario1) !important;
}

.DrawerSelectorItem__number {
  display: flex;
  padding: 0 .4em;
  justify-content: center;
  align-items: center;
}

.radio__input:checked + .radio__label > .DrawerSelectorItem {
  background-color: var(--selected-tab-bgcolor);
  color: var(--selected-tab-color);
  border: var(--border-base);
}
