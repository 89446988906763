html {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  padding-bottom: 3em;
  overflow-x: hidden;
}

button {
  min-width: var(--a11y-target-min-width);
  min-height: var(--a11y-target-min-height);
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

ul {
  padding-left: 1.5rem;
}

li {
  list-style-type: disc;
}

main {
  margin: 0 var(--horizontal-margin-main);
}

:last-child {
  margin-bottom: 0;
}

figure {
  margin: 0;
}

legend {
  margin: var(--legend-margin);
  float: left;
  font-size: var(--legend-font-size);
  font-weight: var(--legend-font-weight);
  text-transform: var(--legend-text-transform);
}

legend + * {
  clear: left;
}

body.dragging * {
  cursor: move !important;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
}

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
